import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Heading from "./estimate-form-heading";
import Fields from "./estimate-form-fields";

const EstimateForm = () => (
  <>
    <SEO
      title={ContentObject.EstimateForm.meta.title}
      description={ContentObject.EstimateForm.meta.description}
    />

    <Layout header={{ position: "absolute", size: "compact" }} footer={false}>
      <section data-csweb="estimate-form">
        <Heading />
        <Fields />
      </section>
    </Layout>
  </>
);

export default EstimateForm;
