import React, { createElement, useState, useEffect } from "react";
import axios from "../../plugins/axios";

import {
  Grid,
  Cell,
  Input,
  Textarea,
  Slider,
  Checkbox,
  Button,
  ProgressBar,
} from "../../components/ui";
import { ContentItem, ContentObject } from "../../components/content";

import { useStyletron } from "baseui";
import {
  ParagraphLarge,
  DisplayXSmall,
  DisplayLarge,
  LabelSmall,
  LabelLarge,
  HeadingLarge,
  ParagraphXSmall,
} from "baseui/typography";
import { FormControl } from "baseui/form-control";
import { Radio, RadioGroup } from "baseui/radio";
import { Modal, ModalBody } from "baseui/modal";

import Dropzone from "react-dropzone";

import Icons from "../../assets/images/small-icons";
import { useForm } from "react-hook-form";
import { validateEmailFormat } from "../../helpers";

const Fields = () => {
  const [css, theme] = useStyletron();
  const {
    register,
    setValue,
    errors,
    handleSubmit,
    getValues,
    formState,
    watch,
    reset,
  } = useForm();
  const { dirty, isSubmitting } = formState;

  const [projectTypes, setProjectTypes] = useState([]);
  const [brief, setBrief] = useState("");
  const [files, setFiles] = useState([]);
  const [agreements, setAgreements] = useState(
    Array(ContentObject.EstimateForm.fields.agreements.length).fill(false)
  );

  const [budget, setBudget] = useState([
    ContentObject.EstimateForm.fields.additional.budget.initial,
  ]);

  const [timeframe, setTimeframe] = useState(null);

  const [progress, setProgress] = useState(15);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);

  const REQUIRED_FIELDS = [
    "name",
    "email",
    "projectTypes",
    "brief",
    "timeframe",
    "agreements0",
  ];

  const watchRequiredFields = watch(REQUIRED_FIELDS);

  useEffect(() => {
    let sum = 0;
    const step = 100 / REQUIRED_FIELDS.length;
    for (const field of REQUIRED_FIELDS) {
      if (
        watchRequiredFields[field] &&
        (field !== "projectTypes" ||
          (field === "projectTypes" &&
            watchRequiredFields[field].length > 0)) &&
        !errors[field]
      ) {
        sum += step;
      }
    }

    if (progress !== sum) {
      setProgress(sum);
    }
  }, [watchRequiredFields]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY + window.innerHeight > document.body.scrollHeight - 50)
        setIsAtBottom(true);
      else setIsAtBottom(false);
    });

    register(
      { name: "brief" },
      { required: ContentObject.EstimateForm.fields.contact.requiredError }
    );
    register(
      { name: "timeframe" },
      { required: ContentObject.EstimateForm.fields.contact.requiredError }
    );
    register(
      { name: "projectTypes" },
      {
        validate: value =>
          value.length > 0 ||
          ContentObject.EstimateForm.fields.contact.requiredError,
      }
    );
    ContentObject.EstimateForm.fields.agreements.forEach((item, index) => {
      register(
        { name: `agreements${index}` },
        {
          validate: value =>
            !!value || ContentObject.EstimateForm.fields.contact.requiredError,
        }
      );
    });
  }, []);

  useEffect(() => {
    agreements.forEach((value, index) => {
      if (getValues(`agreements${index}`) !== value) {
        setValue(`agreements${index}`, value, dirty);
      }
    });
  }, [agreements]);
  useEffect(() => setValue("brief", brief, dirty), [brief]);
  useEffect(() => setValue("timeframe", timeframe, dirty), [timeframe]);
  useEffect(() => setValue("projectTypes", projectTypes, dirty), [
    projectTypes,
  ]);

  async function sendEstimateForm({ name, companyName, email, phone, brief }) {
    try {
      const formData = new FormData();
      const data = {
        name,
        companyName,
        email,
        phone,
        brief,
        title: projectTypes
          .map(
            index =>
              ContentObject.EstimateForm.fields.project.items[index].title
          )
          .join(" + "),
        value: budget[0],
        duration: timeframe,
      };

      Object.keys(data).forEach(key => formData.append(key, data[key]));
      files.forEach((file, index) => formData.append(`files[${index}]`, file));

      await axios.post("/estimate", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: ({ loaded, total }) =>
          setUploadProgress(Math.round((loaded * 100) / total)),
      });
      reset();
      setProjectTypes(null);
      setBrief("");
      setFiles([]);
      setAgreements(
        Array(ContentObject.EstimateForm.fields.agreements.length).fill(false)
      );
      setBudget([ContentObject.EstimateForm.fields.additional.budget.initial]);
      setTimeframe(null);
      setIsFormSent(true);
    } catch (error) {
      // setIsError(true);
    }
  }

  return [
    <article
      key="form"
      data-csweb="estimate-form-fields"
      className={css({ paddingBottom: theme.sizing.scale1600 })}
    >
      <form onSubmit={handleSubmit(sendEstimateForm)} noValidate>
        <Grid>
          <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
            <div
              className={css({
                marginTop: `-${theme.sizing.scale3200}`,
                marginBottom: theme.sizing.scale1200,
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale1200,
                backgroundColor: theme.colors.backgroundPrimary,
                position: "relative",
                zIndex: "2",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              })}
            >
              <Grid flex fill>
                <Cell skip={[0, 0, 3]} span={[4, 8, 6]}>
                  <div
                    className={css({
                      maxWidth: "570px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    })}
                  >
                    <DisplayXSmall
                      $style={{
                        textAlign: "center",
                        fontWeight: "400",
                        position: "relative",
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.contact.title"
                      />
                    </DisplayXSmall>
                    <DisplayLarge
                      color="backgroundTertiary"
                      $style={{
                        marginTop: "-52px",
                        textAlign: "center",
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.contact.number"
                      />
                    </DisplayLarge>
                    <ParagraphLarge
                      $style={{
                        textAlign: "center",
                        marginBottom: theme.sizing.scale1000,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.contact.subtitle"
                      />
                    </ParagraphLarge>

                    <FormControl error={errors.name && errors.name.message}>
                      <Input
                        name="name"
                        placeholder={
                          ContentObject.EstimateForm.fields.contact
                            .namePlaceholder
                        }
                        inputRef={register({
                          required:
                            ContentObject.EstimateForm.fields.contact
                              .requiredError,
                        })}
                        isRequired
                        error={errors.name}
                      />
                    </FormControl>

                    <FormControl
                      error={errors.companyName && errors.companyName.message}
                    >
                      <Input
                        name="companyName"
                        placeholder={
                          ContentObject.EstimateForm.fields.contact
                            .companyNamePlaceholder
                        }
                        inputRef={register()}
                        error={errors.companyName}
                      />
                    </FormControl>

                    <FormControl error={errors.email && errors.email.message}>
                      <Input
                        name="email"
                        placeholder={
                          ContentObject.EstimateForm.fields.contact
                            .emailPlaceholder
                        }
                        inputRef={register({
                          required:
                            ContentObject.EstimateForm.fields.contact
                              .requiredError,
                          validate: value =>
                            validateEmailFormat(value) ||
                            ContentObject.EstimateForm.fields.contact
                              .emailFormatError,
                        })}
                        isRequired
                        error={errors.email}
                      />
                    </FormControl>

                    <FormControl error={errors.phone && errors.phone.message}>
                      <Input
                        name="phone"
                        placeholder={
                          ContentObject.EstimateForm.fields.contact
                            .phonePlaceholder
                        }
                        inputRef={register()}
                        error={errors.phone}
                      />
                    </FormControl>
                  </div>

                  <div
                    className={css({
                      marginTop: theme.sizing.scale2400,
                      maxWidth: "570px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    })}
                  >
                    <DisplayXSmall
                      $style={{
                        textAlign: "center",
                        fontWeight: "400",
                        position: "relative",
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.project.title"
                      />
                    </DisplayXSmall>
                    <DisplayLarge
                      color="backgroundTertiary"
                      $style={{
                        marginTop: "-52px",
                        textAlign: "center",
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.project.number"
                      />
                    </DisplayLarge>
                    <ParagraphLarge
                      $style={{
                        textAlign: "center",
                        marginBottom: theme.sizing.scale1000,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.project.subtitle"
                      />
                    </ParagraphLarge>

                    <div
                      className={css({
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: theme.sizing.scale600,
                        [theme.breakpoints.mediaQueries.medium]: {
                          flexWrap: "wrap",
                        },
                      })}
                    >
                      {ContentObject.EstimateForm.fields.project.items.map(
                        (item, index) => (
                          <div
                            key={index}
                            className={css({
                              [theme.breakpoints.mediaQueries.medium]: {
                                margin: theme.sizing.scale600,
                              },
                              ":hover [data-csweb=ui-circle]": {
                                borderTopColor: theme.colors.primary,
                                borderRightColor: theme.colors.primary,
                                borderBottomColor: theme.colors.primary,
                                borderLeftColor: theme.colors.primary,
                                cursor: "pointer",
                              },
                            })}
                          >
                            <div
                              data-csweb="ui-circle"
                              className={css({
                                borderTopStyle: "solid",
                                borderRightStyle: "solid",
                                borderBottomStyle: "solid",
                                borderLeftStyle: "solid",
                                borderColor: projectTypes.includes(index)
                                  ? theme.colors.primary
                                  : theme.colors.backgroundLightAccent,
                                borderTopWidth: "1px",
                                borderRightWidth: "1px",
                                borderBottomWidth: "1px",
                                borderLeftWidth: "1px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "75px",
                                height: "75px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                borderTopLeftRadius: "50%",
                                borderTopRightRadius: "50%",
                                borderBottomRightRadius: "50%",
                                borderBottomLeftRadius: "50%",
                                transitionProperty: "all",
                                transitionDuration: "200ms",
                                transitionTimingFunction: "ease-in-out",
                                backgroundColor: projectTypes.includes(index)
                                  ? theme.colors.primary
                                  : theme.colors.backgroundPrimary,
                              })}
                              onClick={() => {
                                if (!projectTypes.includes(index)) {
                                  setProjectTypes(projectTypes => [
                                    ...projectTypes,
                                    index,
                                  ]);
                                } else {
                                  setProjectTypes(projectTypes => [
                                    ...projectTypes.filter(x => x !== index),
                                  ]);
                                }
                              }}
                            >
                              {createElement(Icons[item.icon], {
                                "data-csweb": "ui-icon",
                                className: css({
                                  transitionProperty: "all",
                                  transitionDuration: "200ms",
                                  transitionTimingFunction: "ease-in-out",
                                  filter: projectTypes.includes(index)
                                    ? "brightness(0) invert(1)"
                                    : "none",
                                }),
                              })}
                            </div>
                            <LabelSmall
                              $style={{
                                marginTop: theme.sizing.scale600,
                                textAlign: "center",
                                textTransform: "uppercase",
                                fontWeight: "500",
                              }}
                            >
                              {item.title}
                            </LabelSmall>
                          </div>
                        )
                      )}
                    </div>
                    {errors.projectTypes && (
                      <ParagraphXSmall color="negative">
                        {errors.projectTypes.message}
                      </ParagraphXSmall>
                    )}
                    <div
                      className={css({
                        marginTop: theme.sizing.scale900,
                      })}
                    >
                      <FormControl error={errors.brief && errors.brief.message}>
                        <Textarea
                          name="brief"
                          value={brief}
                          rows={6}
                          onChange={e => setBrief(e.target.value)}
                          placeholder={
                            ContentObject.EstimateForm.fields.project
                              .briefPlaceholder
                          }
                          isRequired
                          error={errors.brief}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div
                    className={css({
                      marginTop: theme.sizing.scale2400,
                      maxWidth: "570px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    })}
                  >
                    <DisplayXSmall
                      $style={{
                        textAlign: "center",
                        fontWeight: "400",
                        position: "relative",
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.title"
                      />
                    </DisplayXSmall>
                    <DisplayLarge
                      color="backgroundTertiary"
                      $style={{
                        marginTop: "-52px",
                        textAlign: "center",
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.number"
                      />
                    </DisplayLarge>

                    <LabelLarge
                      $style={{
                        marginTop: theme.sizing.scale1000,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.budget.title"
                      />
                    </LabelLarge>
                    <ParagraphLarge
                      $style={{
                        marginBottom: theme.sizing.scale800,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.budget.subtitle"
                      />
                    </ParagraphLarge>

                    <Input
                      kind="outlined"
                      value={
                        budget > 0
                          ? ContentObject.EstimateForm.fields.additional.budget
                              .currency +
                            " " +
                            new Intl.NumberFormat("en-US", {
                              notation: "compact",
                              compactDisplay: "short",
                            }).format(budget)
                          : "TBD"
                      }
                      onChange={e => setBudget(e.target.value)}
                    />
                    <Slider
                      disabled={isSubmitting}
                      value={budget}
                      min={
                        ContentObject.EstimateForm.fields.additional.budget
                          .min -
                        ContentObject.EstimateForm.fields.additional.budget
                          .offset
                      }
                      max={
                        ContentObject.EstimateForm.fields.additional.budget.max
                      }
                      step={
                        ContentObject.EstimateForm.fields.additional.budget.step
                      }
                      onChange={({ value }) => budget && setBudget(value)}
                      onFinalChange={({ value }) => console.log(value)}
                      overrides={{
                        // eslint-disable-next-line
                        TickBar: ({ $min, $max }) => (
                          <div
                            className={css({
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              paddingRight: theme.sizing.scale600,
                              paddingLeft: theme.sizing.scale600,
                              paddingBottom: theme.sizing.scale400,
                              color: theme.colors.contentPrimary,
                            })}
                          >
                            <div>TBD</div>
                            <div
                              className={css({
                                marginLeft: theme.sizing.scale750,
                              })}
                            >
                              {
                                ContentObject.EstimateForm.fields.additional
                                  .budget.currency
                              }{" "}
                              {new Intl.NumberFormat("en-US", {
                                notation: "compact",
                                compactDisplay: "short",
                              }).format(
                                $min +
                                  ContentObject.EstimateForm.fields.additional
                                    .budget.offset
                              )}
                            </div>
                            <div className={css({ marginLeft: "auto" })}>
                              {
                                ContentObject.EstimateForm.fields.additional
                                  .budget.currency
                              }{" "}
                              {new Intl.NumberFormat("en-US", {
                                notation: "compact",
                                compactDisplay: "short",
                              }).format($max)}
                            </div>
                          </div>
                        ),
                      }}
                    />

                    <LabelLarge
                      $style={{
                        marginTop: theme.sizing.scale1000,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.timeframe.title"
                      />
                    </LabelLarge>
                    <ParagraphLarge
                      $style={{
                        marginBottom: theme.sizing.scale800,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.timeframe.subtitle"
                      />
                    </ParagraphLarge>

                    <RadioGroup
                      disabled={isSubmitting}
                      align="horizontal"
                      name="timeframe"
                      onChange={e => setTimeframe(e.target.value)}
                      value={timeframe}
                      overrides={{
                        RadioGroupRoot: {
                          style: {
                            display: "flex",
                            justifyContent: "space-between",
                            marginLeft: `-${theme.sizing.scale400}`,
                            marginRight: `-${theme.sizing.scale400}`,
                          },
                        },
                        Root: {
                          style: ({ $checked, $isHovered }) => ({
                            paddingTop: theme.sizing.scale600,
                            paddingBottom: theme.sizing.scale600,
                            paddingLeft: theme.sizing.scale600,
                            paddingRight: theme.sizing.scale600,
                            marginLeft: theme.sizing.scale400,
                            marginRight: theme.sizing.scale400,
                            borderColor:
                              $checked || $isHovered
                                ? theme.colors.primary
                                : "rgb(238 238 238)",
                            borderLeftWidth: "1px",
                            borderTopWidth: "1px",
                            borderRightWidth: "1px",
                            borderBottomWidth: "1px",
                            borderTopStyle: "solid",
                            borderRightStyle: "solid",
                            borderBottomStyle: "solid",
                            borderLeftStyle: "solid",
                            position: "relative",
                            borderTopLeftRadius: theme.borders.radius300,
                            borderTopRightRadius: theme.borders.radius300,
                            borderBottomRightRadius: theme.borders.radius300,
                            borderBottomLeftRadius: theme.borders.radius300,
                            flexGrow: "1",
                            textAlign: "center",
                            transitionProperty: "all",
                            transitionDuration: "200ms",
                            transitionTimingFunction: "ease-in-out",
                            backgroundColor: $checked
                              ? theme.colors.primary
                              : "unset",
                          }),
                        },
                        Label: {
                          style: ({ $checked }) => ({
                            paddingLeft: theme.sizing.scale0,
                            paddingRight: theme.sizing.scale0,
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: $checked
                              ? theme.colors.white
                              : theme.colors.contentPrimary,
                            fontSize: "13px",
                            fontWeight: "700",
                          }),
                        },
                        RadioMarkOuter: () => null,
                      }}
                    >
                      {ContentObject.EstimateForm.fields.additional.timeframe.options.map(
                        (item, index) => (
                          <Radio key={index} value={item.value}>
                            {item.label}
                          </Radio>
                        )
                      )}
                    </RadioGroup>
                    {errors.timeframe && (
                      <ParagraphXSmall color="negative">
                        {errors.timeframe.message}
                      </ParagraphXSmall>
                    )}

                    <LabelLarge
                      $style={{
                        marginTop: theme.sizing.scale1000,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.files.title"
                      />
                    </LabelLarge>
                    <ParagraphLarge
                      $style={{
                        marginBottom: theme.sizing.scale800,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.files.subtitle"
                      />
                    </ParagraphLarge>

                    {files.map((file, index) => (
                      <div
                        key={file.name}
                        className={css({
                          display: "flex",
                          justifyContent: "space-between",
                          borderTop: `1px solid ${theme.colors.backgroundLightAccent}`,
                          padding: "4px 0",
                        })}
                      >
                        <ParagraphLarge>{file.name}</ParagraphLarge>
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            setFiles(files =>
                              files.filter((_, _index) => _index !== index)
                            );
                          }}
                          kind="link"
                        >
                          Remove
                        </Button>
                      </div>
                    ))}

                    {isSubmitting && files.length > 0 && (
                      <ProgressBar
                        value={uploadProgress}
                        getProgressLabel={value => `Uploading files ${value}%`}
                        showLabel
                        successValue={100}
                      />
                    )}

                    <Dropzone
                      accept={[
                        "application/pdf",
                        "application/msword",
                        "application/excel",
                        "image/png",
                        "image/jpeg",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      ]}
                      onDrop={acceptedFiles =>
                        setFiles(files => [...files, ...acceptedFiles])
                      }
                      disabled={isSubmitting}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className={css({
                            paddingTop: theme.sizing.scale900,
                            paddingBottom: theme.sizing.scale900,
                            paddingLeft: theme.sizing.scale600,
                            paddingRight: theme.sizing.scale600,
                            backgroundColor: theme.colors.backgroundSecondary,
                            borderTopLeftRadius: theme.borders.radius300,
                            borderTopRightRadius: theme.borders.radius300,
                            borderBottomRightRadius: theme.borders.radius300,
                            borderBottomLeftRadius: theme.borders.radius300,
                            textAlign: "center",
                            cursor: "pointer",
                            borderTopWidth: "0px",
                            borderRightWidth: "0px",
                            borderBottomWidth: "0px",
                            borderLeftWidth: "0px",
                            ":hover [data-csweb=dropzone-label]": {
                              transform: "translateY(0px)",
                              opacity: "1",
                            },
                            ":hover [data-csweb=dropzone-icon]": {
                              transform: "translateY(0px)",
                              opacity: "1",
                            },
                          })}
                        >
                          <input {...getInputProps()} />
                          <p
                            className={css({
                              color: theme.colors.contentStateDisabled,
                            })}
                          >
                            {createElement(Icons.Upload, {
                              className: css({
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginBottom: theme.sizing.scale400,
                                transform: "translateY(20px)",
                                transitionProperty: "all",
                                transitionDuration: "200ms",
                                transitionTimingFunction: "ease-in-out",
                                opacity: "0",
                              }),
                              "data-csweb": "dropzone-icon",
                            })}
                            <ContentItem
                              data-csweb="dropzone-label"
                              $style={{
                                display: "block",
                                position: "relative",
                                transform: "translateY(-20px)",
                                transitionProperty: "all",
                                transitionDuration: "200ms",
                                transitionTimingFunction: "ease-in-out",
                                opacity: ".45",
                              }}
                              scope="EstimateForm"
                              accessor="fields.additional.files.label"
                            />
                          </p>
                        </div>
                      )}
                    </Dropzone>

                    <ParagraphXSmall color="contentTertiary">
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.files.hint"
                      />
                    </ParagraphXSmall>

                    <LabelLarge
                      $style={{
                        marginTop: theme.sizing.scale1000,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.nda.title"
                      />
                    </LabelLarge>
                    <ParagraphLarge
                      $style={{
                        marginBottom: theme.sizing.scale1400,
                      }}
                    >
                      <ContentItem
                        scope="EstimateForm"
                        accessor="fields.additional.nda.subtitle"
                      />

                      <a
                        href=""
                        className={css({
                          color: theme.colors.contentAccent,
                          textDecoration: "none",
                          "::after": {
                            content:
                              "attr(data-name) '@' attr(data-domain) '.' attr(data-tld)",
                          },
                        })}
                        data-name={
                          ContentObject.EstimateForm.fields.additional.nda.email
                            .name
                        }
                        data-domain={
                          ContentObject.EstimateForm.fields.additional.nda.email
                            .domain
                        }
                        data-tld={
                          ContentObject.EstimateForm.fields.additional.nda.email
                            .tld
                        }
                        onClick={e => {
                          e.preventDefault();
                          window.location.href =
                            "mailto:" +
                            e.currentTarget.dataset.name +
                            "@" +
                            e.currentTarget.dataset.domain +
                            "." +
                            e.currentTarget.dataset.tld;
                          return false;
                        }}
                      ></a>
                    </ParagraphLarge>

                    {ContentObject.EstimateForm.fields.agreements.map(
                      (item, index) => (
                        <FormControl
                          key={index}
                          error={
                            errors[`agreements${index}`] &&
                            errors[`agreements${index}`].message
                          }
                        >
                          <Checkbox
                            checked={agreements[index]}
                            onChange={e =>
                              setAgreements(agreements => {
                                const _agreements = [...agreements];
                                _agreements[index] = e.target.checked;
                                return _agreements;
                              })
                            }
                            error={errors[`agreements${index}`]}
                            labelPlacement="right"
                          >
                            {item.label}
                          </Checkbox>
                        </FormControl>
                      )
                    )}
                  </div>
                </Cell>
              </Grid>
            </div>
          </Cell>
        </Grid>
        <button type="submit" className={css({ display: "none" })}></button>
      </form>
    </article>,
    <div
      key="progressbar"
      data-csweb="estimate-form-progressbar"
      className={css({
        position: "fixed",
        bottom: "0px",
        left: "0px",
        right: "0px",
        height: isAtBottom ? "120px" : "60px",
        backgroundColor: theme.colors.backgroundSecondary,
        zIndex: "4",
        transitionProperty: "all",
        transitionDuration: "200ms",
        transitionTimingFunction: "ease-in-out",
      })}
    >
      <div
        className={css({
          position: "absolute",
          left: "0px",
          top: "0px",
          bottom: "0px",
          width: `${progress}%`,
          transitionProperty: "all",
          transitionDuration: "200ms",
          transitionTimingFunction: "ease-in-out",
          overflow: "hidden",
          background:
            "linear-gradient(90deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
          zIndex: "3",
        })}
      >
        <div
          className={css({
            position: "absolute",
            left: "0px",
            top: "0px",
            bottom: "0px",
            width: "100vw",
            overflowY: "scroll",
            boxSizing: "border-box",
            zIndex: "3",
          })}
        >
          <Grid $style={{ height: "100%", alignItems: "center" }}>
            <Cell span={10} skip={1}>
              <div
                className={css({
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                })}
              >
                <DisplayXSmall
                  color="white"
                  $style={{
                    transitionProperty: "all",
                    transitionDuration: "200ms",
                    transitionTimingFunction: "ease-in-out",
                    fontSize: isAtBottom ? "34px" : "22px",
                    fontWeight: "600",
                  }}
                >
                  {
                    ContentObject.EstimateForm.fields.progressbar.steps
                      .filter(s => progress >= s.value)
                      .reverse()[0].label
                  }
                </DisplayXSmall>

                <Button
                  className={css({
                    position: "relative",
                    zIndex: "5",
                    alignSelf: "center",
                  })}
                  color="white"
                  kind={
                    ContentObject.EstimateForm.fields.progressbar.button.kind
                  }
                  label={
                    ContentObject.EstimateForm.fields.progressbar.button.label
                  }
                >
                  {ContentObject.EstimateForm.fields.progressbar.button.title}
                </Button>
              </div>
            </Cell>
          </Grid>
        </div>
      </div>
      <Grid $style={{ height: "100%", alignItems: "center" }}>
        <Cell span={10} skip={1}>
          <div
            className={css({
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
            })}
          >
            <DisplayXSmall
              color="contentSecondary"
              $style={{
                transitionProperty: "all",
                transitionDuration: "200ms",
                transitionTimingFunction: "ease-in-out",
                fontSize: isAtBottom ? "34px" : "22px",
                fontWeight: "600",
              }}
            >
              {
                ContentObject.EstimateForm.fields.progressbar.steps
                  .filter(s => progress >= s.value)
                  .reverse()[0].label
              }
            </DisplayXSmall>

            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              className={css({
                position: "relative",
                alignSelf: "center",
              })}
              color="#d3daee"
              kind={ContentObject.EstimateForm.fields.progressbar.button.kind}
              label={ContentObject.EstimateForm.fields.progressbar.button.label}
              onClick={handleSubmit(sendEstimateForm)}
            >
              {ContentObject.EstimateForm.fields.progressbar.button.title}
            </Button>
          </div>
        </Cell>
      </Grid>
    </div>,
    <Modal
      key={"modal"}
      onClose={() => setIsFormSent(false)}
      closeable
      isOpen={isFormSent}
      animate
      autoFocus
      overrides={{
        Root: {
          style: {
            zIndex: 100,
          },
        },
      }}
    >
      <ModalBody>
        <div
          className={css({
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          })}
        >
          <HeadingLarge color={theme.colors.contentAccent}>
            <ContentItem scope="EstimateForm" accessor={`success.heading`} />
          </HeadingLarge>
          <ParagraphLarge>
            <ContentItem scope="EstimateForm" accessor={`success.paragraph`} />
          </ParagraphLarge>
        </div>
      </ModalBody>
    </Modal>,
  ];
};

export default Fields;
